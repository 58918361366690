import React, { useRef, useCallback } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import delve from 'dlv'
import classnames from 'classnames/bind'

import { Title } from 'components/typo'
import Link from 'components/shared/Link'

import useTimeline from 'hooks/useTimeline'
import useTimelinePlayback from 'hooks/useTimelinePlayback'
import NavigationPanel from './NavigationPanel'
import { ReactComponent as Cross } from './cross.svg'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const Overlay = ({
  home,
  panels,
  className,
  toggleVisiblity,
  innerRef,
  visible,
  forwardWrapperRef,
}) => {
  const text = delve(home, 'text')
  const target = delve(home, 'target')
  const href = delve(home, 'href')
  const contentType = delve(home, 'contentType')
  const componentRef = useRef()
  const containerRef = useRef()
  const backgroundRef = useRef()
  const wrapperRef = useRef()
  const crossRef = useRef()

  const setWrapperRef = useCallback((node) => {
    wrapperRef.current = node
    forwardWrapperRef && (forwardWrapperRef.current = node)
  }, [])

  const memoizedToggleVisibility = useCallback(toggleVisiblity, [])

  const openTl = useTimeline({ paused: true }, (tl) => {
    tl.fromTo(
      componentRef.current,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.1,
        ease: 'linear.easeNone',
      },
      '+=0.1'
    )

    tl.fromTo(
      backgroundRef.current,
      {
        scale: 0,
      },
      {
        scale: 1,
        transformOrigin: 'top right',
        ease: 'elastic.out(0.2, 0.3)',
        duration: 0.8,
      },
      '-=0.1'
    )

    tl.fromTo(
      containerRef.current,
      {
        y: 50,
        z: -50,
      },
      {
        y: 0,
        z: 0,
        ease: 'elastic.out(0.3, 0.3)',
        duration: 0.8,
      },
      '-=0.8'
    )

    tl.fromTo(
      wrapperRef.current,
      { opacity: 0 },
      { opacity: 1, ease: 'linear.easeNone', duration: 0.4 },
      '>-0.6'
    )

    tl.fromTo(
      crossRef.current,
      { autoAlpha: 0 },
      { duration: 0.2, autoAlpha: 1, ease: 'linear.easeNone' },
      '>-0.3'
    )

    tl.fromTo(
      crossRef.current,
      { scale: 0.5 },
      { duration: 0.6, scale: 1, ease: 'elastic.out(0.3, 0.3)' },
      '<'
    )
  })

  useIsomorphicLayoutEffect(() => {
    if (visible) {
      openTl.timeScale(1.2)
    } else {
      openTl.timeScale(2.5)
    }
  }, [visible])
  useTimelinePlayback(openTl, visible)

  const setComponentref = useCallback((node) => {
    componentRef.current = node
    innerRef && (innerRef.current = node)
  }, [])

  return (
    <div className={cx(css.Overlay, className)} ref={setComponentref}>
      <div className={css.container} ref={containerRef}>
        <div className={css.background} ref={backgroundRef} />
        <div ref={setWrapperRef} className={css.wrapper}>
          <div className={cx(css.gradient, css.topGradient)} />
          <ul className={css.list}>
            <li className={css.section}>
              <Title
                tag="p"
                className={css.title}
                size="small"
                theme="purple"
              >
                {href && (
                <Link
                  onClick={memoizedToggleVisibility}
                  target={target}
                  href={href}
                  contentType={contentType}
                >
                  {text}
                </Link>
                  )}
              </Title>
            </li>
            {panels &&
              panels.map((panel, i) => (
                <NavigationPanel
                  key={`${panel.navigationLabel}-${i}`}
                  onClick={memoizedToggleVisibility}
                  {...panel}
                />
              ))}
          </ul>
          <div className={cx(css.gradient, css.bottomGradient)} />
        </div>

        <Cross ref={crossRef} className={css.cross} onClick={memoizedToggleVisibility} />
      </div>
    </div>
  )
}

export default React.forwardRef((props, ref) => (
  <Overlay innerRef={ref} {...props} />
))
