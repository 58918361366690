import React from 'react'
import { useCopyToClipboard } from 'react-use'

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'

import tomorrow from 'react-syntax-highlighter/dist/cjs/styles/prism/tomorrow'
import transparent from './transparent'
import light from './light'
import green from './green'
import purple from './purple'

import CopyIcon from 'components/icons/CopyIcon'

import css from 'components/shared/CliBox/styles.module.scss'

const themes = {
  tomorrow,
  transparent,
  light,
  green,
  purple,
}

const Code = ({
  className,
  language,
  content,
  theme = 'tomorrow',
  noMargin,
}) => {
  // fallback to HTML if Vue is set as language (not supported yet by react-syntax-highlighter)
  const formattedLanguage = language === 'vue' ? 'html' : language
  const languagesWithoutLineNumbers = ['bash', 'sh', 'zsh', undefined]

  const [_, copyToClipboard] = useCopyToClipboard()

  return content ? (
    <>
      {!content.includes('npx create-strapi-app') && ( // Not adding it on the side copy/paste box in blog pages
        <button
          style={{
            float: 'right',
            right: '40px',
            top: '20px',
            position: 'relative',
          }}
          onClick={() => copyToClipboard(content)}
          className={css.copyIcon}
        >
          <CopyIcon />
        </button>
      )}

      <SyntaxHighlighter
        className={className}
        language={formattedLanguage}
        style={themes[theme]}
        customStyle={{ margin: `${noMargin ? 0 : '35px'} 0` }}
        showLineNumbers={
          languagesWithoutLineNumbers.includes(language) ? false : true
        }
      >
        {content}
      </SyntaxHighlighter>
    </>
  ) : null
}

export default Code
