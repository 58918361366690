import React, { Component } from 'react'
import classnames from 'classnames/bind'

import { Text } from 'components/typo'
import Layout from 'components/shared/Layout'
import Logo from 'components/shared/Logo'
import Link from 'components/shared/Link'
import FullWidthCTABanner from 'components/shared/FullWidthCTABanner'
import SocialLinks from './SocialLinks'
import DesignStudio from './DesignStudio'
import MenuColumn from './MenuColumn'

import soc2 from './Badges/soc2.png'
import { ReactComponent as GDPR } from './Badges/gdpr.svg'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const currentYear = new Date(Date.now()).getFullYear()

export default class Footer extends Component {
  static defaultProps = {
    sections: [],
  }

  render() {
    const {
      push,
      tagline,
      sections,
      sublinks,
      socials,
      showDesignStudio,
      darkMode,
    } = this.props

    return (
      <footer className={cx(css.Footer, { darkMode })}>
        {push && (
          <FullWidthCTABanner
            cliCommand={push.cliCommand}
            title={push.title}
            buttons={push.buttons}
            darkMode={darkMode}
          />
        )}

        <Layout type="maxWidth">
          <Layout type="wrapper">
            <div className={css.content}>
              <div className={css.leftContent}>
                <Logo className={css.logo} dark={!darkMode} alt="Strapi logo" />
                {tagline && (
                  <Text
                    className={css.tagline}
                    size="legend"
                    theme={darkMode ? 'grey-400' : 'gray'}
                  >
                    {tagline}
                  </Text>
                )}
                <div className={css.badges}>
                  <img src={soc2?.src} className={css.badge} alt="Soc 2" />
                  <GDPR className={css.badge} alt="GDPR" />
                </div>
              </div>
              {sections && (
                <nav className={css.navigation}>
                  {sections.map((section, id) => {
                    return (
                      <MenuColumn
                        key={id}
                        title={section.title}
                        links={section.links.map((link) => ({
                          ...link,
                          id: `${id}-${link?.text}`,
                        }))}
                        darkMode={darkMode}
                      />
                    )
                  })}
                </nav>
              )}
            </div>
            <div className={cx(css.legals, { darkMode })}>
              {sublinks && (
                <ul className={css.subLinks}>
                  <Text
                    className={css.subLink}
                    tag="li"
                    theme={darkMode ? 'grey-500' : 'gray'}
                    size="smaller"
                  >
                    © {currentYear}, Strapi
                  </Text>

                  {sublinks.map(({ text, href, contentType }, id) => (
                    <li className={css.subLink} key={id}>
                      <Link href={href} contentType={contentType}>
                        <Text
                          className={css.subLink}
                          tag="span"
                          theme={darkMode ? 'grey-500' : 'gray'}
                          size="smaller"
                        >
                          {text}
                        </Text>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              {showDesignStudio && <DesignStudio />}
              {socials && <SocialLinks {...socials} darkMode={darkMode} />}
            </div>
          </Layout>
        </Layout>
      </footer>
    )
  }
}
